import { Location, WindowLocation } from "@reach/router";
import React from "react";
import { Helmet } from "react-helmet";
import {
  ENABLE_ONETRUST,
  ONETRUST_DOMAIN_SCRIPT,
  ONETRUST_SRC,
} from "../../constants/oneTrust";

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  location: WindowLocation<unknown>;
}

const OneTrustInternals: React.FunctionComponent<IProps> = ({
  children,
  location,
}) => {
  return (
    <>
      {ENABLE_ONETRUST && (
        <Helmet>
          <script
            src={ONETRUST_SRC}
            type="text/javascript"
            data-domain-script={ONETRUST_DOMAIN_SCRIPT}
          />
          <script type="text/javascript" src="/lib/OptanonWrapper.js"></script>
        </Helmet>
      )}
      {children}
    </>
  );
};

const OneTrust: React.FC = ({ children }) => {
  return (
    <Location>
      {({ location }) => (
        <OneTrustInternals location={location}>{children}</OneTrustInternals>
      )}
    </Location>
  );
};

export default OneTrust;
