import { useState, useEffect, useCallback } from "react";
import { waitForGlobal } from "../../../utils";

/*
 * Custom hooks: useCheckConsentsOneTrust
 * Params:
 *   cookieCategory
 *     type: string
 *     reference: use enum cookiesCategory on constant/OneTrust.tsx
 * Example:
 *   const isAllowedByOneTrust = useCheckConsentsOneTrust(
 *     cookiesCategory.PERFORMANCE
 *   );
 */

export const useCheckConsentsOneTrust = (cookiesCategory: string) => {
  const [state, setState] = useState(false);

  const onConsentChangedCallback = useCallback((code: string) => {
    if (window?.OnetrustActiveGroups?.includes(code)) {
      setState(true);
    } else {
      setState(false);
    }
  }, []);

  const onOnetrustReadyCallback = useCallback(
    (code: string) => () => {
      onConsentChangedCallback(code);
      window?.OneTrust?.OnConsentChanged(() => onConsentChangedCallback(code));
    },
    []
  );

  useEffect(() => {
    waitForGlobal("OneTrust", onOnetrustReadyCallback(cookiesCategory));
  }, []);

  return state;
};
