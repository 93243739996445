/* eslint-disable @typescript-eslint/no-var-requires */
/* ---------------------------------
gatsby-browser
--------------------------------- */

import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react";
import React from "react";
import customTheme from "./src/theme";
import OneTrust from "./src/components/cookies/OneTrust";
import { Analytics } from "./src/components/analytics/Analytics";
// custom fonts
import "./static/fonts/You-And-I-modern/index.css";

const version = require("./package.json").version;
console.log("Current release version:", version);

export const onClientEntry = () => {
  if (process.env.NODE_ENV === "development") {
    const { worker } = require("./src/mocks/browser");
    worker.start({ onUnhandledRequest: "bypass" });
  }
};

// custom theme
const theme = extendTheme(customTheme);

export const wrapRootElement = ({ element }) => {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Analytics />
      <OneTrust>{element}</OneTrust>
    </ChakraProvider>
  );
};

// exports.disableCorePrefetching = () => true;
